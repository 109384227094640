<template>
  <v-app id="inspire">
    <div class="app-context">
      <app-header :headerOption="headerOption" ref="appheader"></app-header>
      <div class="context flex withSide">
        <side-menu :sideSet="sideSet"></side-menu>
        <div class="hospital-list">
          <div id="app">
            <div style="width: 97%">
              <div
                style="
                  float: right;
                  border: 1px solid #78909c;
                  border-radius: 5px;
                  width: 400px;
                  height: 60px;
                "
              >
                <tr style="position: relative; left: 22px; top: 6px">
                  <td>
                    <div
                      style="
                        background-color: #0d47a1;
                        width: 60px;
                        height: 16px;
                      "
                    ></div>
                  </td>
                  <div style="width: 10px"></div>
                  <td>
                    <span> 수신의뢰</span>
                  </td>
                  <td>
                    <div style="width: 20px"></div>
                  </td>
                  <td>
                    <div
                      style="
                        background-color: #64b5f6;
                        width: 60px;
                        height: 16px;
                      "
                    ></div>
                  </td>
                  <div style="width: 10px"></div>
                  <td>
                    <span> 수신의뢰 회신 완료</span>
                  </td>
                </tr>
                <tr style="position: relative; left: 22px; top: 8px">
                  <td>
                    <div
                      style="
                        background-color: #004d40;
                        width: 60px;
                        height: 16px;
                      "
                    ></div>
                  </td>
                  <div style="width: 10px"></div>
                  <td>
                    <span> 발신의뢰</span>
                  </td>
                  <td>
                    <div style="width: 20px"></div>
                  </td>
                  <td>
                    <div
                      style="
                        background-color: #4db6ac;
                        width: 60px;
                        height: 16px;
                      "
                    ></div>
                  </td>
                  <div style="width: 10px"></div>
                  <td>
                    <span> 발신의뢰 회신 완료</span>
                  </td>
                </tr>
              </div>
            </div>
            <v-row class="fill-height" style="width: 100%">
              <v-col>
                <v-sheet height="64">
                  <v-toolbar flat>
                    <v-btn
                      outlined
                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                    >
                      Today
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu id="calendar_Outside" bottom right offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                          <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                  ></v-calendar>
                  <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    content-class="calendarInside"
                    offset-y
                  >
                    <v-card color="grey lighten-4" min-width="350px" flat>
                      <v-toolbar :color="selectedEvent.color" dark>
                        <v-spacer style="max-width: 30px"></v-spacer>
                        <v-btn icon>
                          <!--<v-icon>mdi-pencil</v-icon>-->
                          <v-toolbar-title>환자 이름</v-toolbar-title>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-title
                          v-html="selectedEvent.name"
                        ></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <!--<v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>-->
                      </v-toolbar>
                      <v-card-text>
                        <span v-html="selectedEvent.HospNm"></span>
                        <v-spacer></v-spacer>
                        <span v-html="selectedEvent.DrNm"></span>
                      </v-card-text>
                      <div style="border: 3px soild #fff"></div>
                      <v-card-text>
                        <span
                          >증상 :
                          <span v-html="selectedEvent.diagNm"></span>
                        </span>
                        <v-spacer></v-spacer>
                        <span
                          >증상코드 :
                          <span v-html="selectedEvent.diagCd"></span>
                        </span>
                      </v-card-text>
                      <v-card-text>
                        <span
                          >의뢰내용 :
                          <span v-html="selectedEvent.referConts"></span
                        ></span>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="secondary"
                          @click="selectedOpen = false"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
            <v-dialog max-width="600px" persistent v-model="dialog">
              <v-card>
                <v-card-title>
                  <h3>일정 추가</h3>
                </v-card-title>
                <v-card-text>
                  <v-form class="px-3" ref="form">
                    <v-text-field
                      label="일정"
                      v-model="calendar.title"
                      prepend-icon="mdi-folder-marker"
                    ></v-text-field>
                    <v-textarea
                      label="상세설명"
                      v-model="calendar.content"
                      prepend-icon="mdi-pencil"
                    ></v-textarea>
                    <v-row>
                      <v-col cols="6" class="pb-0">
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              slot="activator"
                              label="시작일"
                              readonly
                              prepend-icon="mdi-calendar-month"
                              v-on="on"
                              :value="calendar.startDate"
                              class=""
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="calendar.startDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" class="pb-0">
                        <v-menu
                          :close-on-content-click="false"
                          v-model="startTimer"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="시작 시간"
                              readonly
                              :value="calendar.startTime"
                              prepend-icon="mdi-timer"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="startTimer"
                            v-model="calendar.startTime"
                          >
                            <v-btn class="mx-auto" @click="selectTime"
                              >선택
                            </v-btn>
                          </v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="pt-0">
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              slot="activator"
                              label="종료일"
                              readonly
                              prepend-icon="mdi-calendar-month"
                              v-on="on"
                              :value="calendar.endDate"
                              class=""
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="calendar.endDate"
                            :allowed-dates="allowedDates"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" class="pt-0">
                        <v-menu
                          :close-on-content-click="false"
                          v-model="endTimer"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="종료 시간"
                              readonly
                              :value="calendar.endTime"
                              prepend-icon="mdi-timer"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="endTimer"
                            v-model="calendar.endTime"
                          >
                            <v-btn class="mx-auto" @click="selectTime"
                              >선택
                            </v-btn>
                          </v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <div class="text-center">
                      <v-btn
                        text
                        class="primary white--text mx-2 mt-3"
                        @click="submit"
                      >
                        추가
                      </v-btn>
                      <v-btn
                        text
                        class="primary white--text mx-2 mt-3"
                        @click="close"
                      >
                        닫기
                      </v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>

            <default-popup v-if="checkPopup" :popupSet="popupSet" />
          </div>
        </div>
      </div>
      <app-footer></app-footer>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import DefaultPopup from "@/components/modal/DefaultPopup";
import AppFooter from "@/components/AppFooter";
import SideMenu from "@/components/sidemenu/SideMenu";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    DefaultPopup,
    AppHeader,
    AppFooter,
    SideMenu,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [
        {
          name: "test",
          start: "2021-06-23",
          end: "2021-06-24",
          color: "red",
        },
      ],
      colors: [
        "blue darken-4",
        "blue lighten-2",
        "teal darken-4",
        "teal lighten-2",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],

      startTimer: false,
      endTimer: false,
      calendar: {
        title: "",
        content: "",
        startDate: "",
        startTime: "",
        dialog: false,
      },
      popupSet: {},
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("util", ["GET_DATE_INFO", "GET_REFER_CALENDAR_LIST"]),
    dialog() {
      //console.log("dialog");
      this.calendar.dialog = this.GET_DATE_INFO.dialog;
      return this.calendar.dialog;
    },
    /*calendar() {
      console.log("cal");
      this.calendar = this.GET_DATE_INFO;
      return this.calendar;
    },*/
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("util", ["CLOSE_CALENDAR_DIALOG", "REQUEST_ADD_EVENT"]),
    ...mapActions("util", ["STORE_DATE_INFO", "BRING_REFER_LIST"]),
    getMaskedName(strName) {
      if (strName.length > 2) {
        var originName = strName.split("");
        originName.forEach(function (name, i) {
          if (i === 0 || i === originName.length - 1) return;
          originName[i] = "#";
        });
        var joinName = originName.join();
        return joinName.replace(/,/g, "");
      } else {
        var pattern = /.$/; // 정규식
        return strName.replace(pattern, "*");
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.$moment().format("YYYY-MM-DD");
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async updateRange(data) {
      const events = [];
      /*events.push({
        name: data.title,
        start: new Date(`${data.startDate}T00:00:00`),
        end: new Date(`${data.endDate}T23:59:59`),
        color: this.colors[this.rnd(0, this.colors.length - 1)],
      });*/
      var searchOpt = {
        receiveOrder: 0, // default 옵션,
      };
      try {
        await this.BRING_REFER_LIST(searchOpt).then(() => {
          //console.log(this.GET_REFER_CALENDAR_LIST);
          for (
            let i = 0;
            i < this.GET_REFER_CALENDAR_LIST.listRcv.length;
            i++
          ) {
            events.push({
              name:
                this.GET_REFER_CALENDAR_LIST.listRcv[i].rcvYn === "Y"
                  ? this.GET_REFER_CALENDAR_LIST.listRcv[i].patNm + "(회신완료)"
                  : this.GET_REFER_CALENDAR_LIST.listRcv[i].agreeYn === "Y"
                  ? this.GET_REFER_CALENDAR_LIST.listRcv[i].patNm
                  : this.getMaskedName(
                      this.GET_REFER_CALENDAR_LIST.listRcv[i].patNm
                    ) + "(환자동의필요)",
              label: this.GET_REFER_CALENDAR_LIST.listRcv[i].patNm,
              start: new Date(
                `${this.$moment(
                  this.GET_REFER_CALENDAR_LIST.listRcv[i].referDt
                ).format("YYYY-MM-DD")}T00:00:00`
              ),
              diagNm: this.GET_REFER_CALENDAR_LIST.listRcv[i].diagNm,
              diagCd: this.GET_REFER_CALENDAR_LIST.listRcv[i].diagCd,
              HospNm:
                "의뢰병원 : " +
                this.GET_REFER_CALENDAR_LIST.listRcv[i].sndHospNm,
              DrNm:
                "의뢰의사 : " + this.GET_REFER_CALENDAR_LIST.listRcv[i].sndDrNm,
              referConts: this.GET_REFER_CALENDAR_LIST.listRcv[i].referConts,
              color:
                this.GET_REFER_CALENDAR_LIST.listRcv[i].rcvYn === "Y"
                  ? this.colors[1]
                  : this.colors[0],
            });
          }
          for (
            let i = 0;
            i < this.GET_REFER_CALENDAR_LIST.listSend.length;
            i++
          ) {
            events.push({
              name:
                this.GET_REFER_CALENDAR_LIST.listSend[i].rcvYn === "Y"
                  ? this.GET_REFER_CALENDAR_LIST.listSend[i].patNm +
                    "(회신완료)"
                  : this.GET_REFER_CALENDAR_LIST.listSend[i].patNm,
              label: this.GET_REFER_CALENDAR_LIST.listSend[i].patNm,
              start: new Date(
                `${this.$moment(
                  this.GET_REFER_CALENDAR_LIST.listSend[i].referDt
                ).format("YYYY-MM-DD")}T00:00:00`
              ),
              diagNm: this.GET_REFER_CALENDAR_LIST.listSend[i].diagNm,
              diagCd: this.GET_REFER_CALENDAR_LIST.listSend[i].diagCd,
              HospNm: this.GET_REFER_CALENDAR_LIST.listSend[i].sndHospNm,
              DrNm: this.GET_REFER_CALENDAR_LIST.listSend[i].sndDrNm,
              referConts: this.GET_REFER_CALENDAR_LIST.listSend[i].referConts,
              color:
                this.GET_REFER_CALENDAR_LIST.listSend[i].rcvYn === "Y"
                  ? this.colors[3]
                  : this.colors[2],
            });
          }
          this.events = events;
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }

      /*const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }*/
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    open(date) {
      //console.log("open");
      //console.log(date);
      this.STORE_DATE_INFO(date);
      this.calendar = this.GET_DATE_INFO;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.REQUEST_ADD_EVENT(this.calendar);
        this.updateRange(this.calendar);
        //this.$store.util.commit("REQUEST_ADD_EVENT", this.calendar);
      }
    },
    close() {
      //console.log("asd");
      this.CLOSE_CALENDAR_DIALOG();
      //this.$store.util.commit("CLOSE_CALENDAR_DIALOG");
    },
    selectTime() {
      this.endTimer = false;
      this.startTimer = false;
    },
    allowedDates(val) {
      let endDate = val.split("-").reduce((a, b) => a + b);
      let startDate = this.calendar.startDate
        .split("-")
        .reduce((a, b) => a + b);
      return endDate >= startDate;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
#app .context {
  .hospital-list {
    position: relative;
    top: -50px;
  }
}
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
